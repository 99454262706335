import React, {Component, Fragment} from 'react';
import TeamMembersGridModule from './TeamMembersGridModule';
import OneColumnModule from './OneColumnModule';
import TwoColumnModule from './TwoColumnModule';
import ServiceGridModule from './ServiceGridModule';
import CaseStudiesGridModule from './CaseStudiesGridModule';
import CalloutModule from './CalloutModule';
import ServicesCategoryPagesModule from './ServicesCategoryPagesModule';
import PageHeaderModule from './PageHeaderModule';
import ImageModule from './ImageModule';
import SingleContentModule from './SingleContentModule';
import FullWidthImageModule from './FullWidthImageModule';
import ImageAndContentModule from './ImageAndContentModule';
import CareerContactFormModule from './CareerContactFormModule';
import SubscribeFormModule from './SubscribeFormModule';
import OurClientsModule from './OurClientsModule';

const moduleDefinitions = [
    {
        name: 'Excerpt_Module',
        component: () => null, // render nothing
    },
    {
        name: 'image_module',
        component: ImageModule,
    },
    {
        name: 'page_header_module',
        component: PageHeaderModule,
    },
    {
        name: 'team_members_grid_module',
        component: TeamMembersGridModule,
    },
    {
        name: 'one_column_module',
        component: OneColumnModule,
    },
    {
        name: 'two_column_module',
        component: TwoColumnModule,
    },
    {
        name: 'service_grid_module',
        component: ServiceGridModule,
    },
    {
        name: 'case_studies_grid_module',
        component: CaseStudiesGridModule,
    },
    {
        name: 'callout_module',
        component: CalloutModule,
    },
    {
        name: 'service_category_pages_module',
        component: ServicesCategoryPagesModule,
    },
    {
        name: 'single_content_module',
        component: SingleContentModule,
    },
    {
        name: 'fullwidth_image_module',
        component: FullWidthImageModule,
    },
    {
        name: 'image_and_content_module',
        component: ImageAndContentModule,
    },
    {
        name: 'video_and_content_module',
        component: ImageAndContentModule,
    },
    {
        name: 'career_contact_form',
        component: CareerContactFormModule,
    },
    {
        name: 'subscribe_form_module',
        component: SubscribeFormModule,
    },
    {
        name: 'our_clients_module',
        component: OurClientsModule,
    },
];

const renderModule = ({definition, pmodule, index}) => {
    const Component = definition.component;
    return <Component key={index} {...definition} {...pmodule} />;
};

//
// Component that takes an array and assembles a bunch of components
//
export default class PageBuilder extends Component {
    static defaultProps = {
        renderModule,
    };

    render() {
        const {page, renderModule} = this.props;
        if (!page) {
            return null;
        }

        const elements = [];
        let index = 0;
        for (const pmodule of page) {
            const definition = moduleDefinitions.find((e) => e.name === pmodule.acfFcLayout);
            if (!definition) {
                console.error('PageBuilder unknown acf_fc_layout: ', pmodule.acfFcLayout, pmodule);
                continue;
            }
            elements.push(renderModule({definition, pmodule, index}));
            ++index;
        }

        return <Fragment>{elements}</Fragment>;
    }
}
